import client from "./client";

export const getQnas = ({
  status,
  isDeleted,
  userPhone,
  userEmail,
  userName,
  title,
  content,
  answer,
  ordering,
  page,
  size,
  isOnlyCount,
  isMy,
}) => {
  return client.get("/inquiry/", {
    params: {
      status,
      isDeleted,
      userPhone,
      userEmail,
      userName,
      title,
      content,
      answer,
      ordering,
      page,
      size,
      isOnlyCount,
      isMy,
    },
  });
};

export const getQna = ({ id }) => client.get(`/inquiry/${id}`);

export const postQna = ({ title, content, status, answer }) =>
  client.post(`/inquiry/`, { title, content, status, answer });

export const putQna = ({ id, title, content, status, answer }) =>
  client.put(`/inquiry/${id}/`, { title, content, status, answer });

export const deleteQnas = ({ ids }) => {
  return client.delete(`/inquiry-multi-delete/`, {
    data: { ids },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
