import React, { useEffect, useState } from "react";
import { Table, Divider, PageHeader, Button, message } from "antd";
import { useNavigate } from "react-router-dom";

import Pagination from "react-js-pagination";

import {
  FlexRowEndContainer,
  FlexRowReverseContainer,
} from "../../common/Container.styles";

import { dateToString } from "../../../utils/common";
import * as api from "../../../apis/product";
import { errorHandler } from "src/utils/eventErrorHandler";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    width: "8%",
  },
  {
    title: "등록일",
    dataIndex: "createdDate",
    render: (date) => dateToString(date),
    width: "8%",
  },
  {
    title: "수정일",
    dataIndex: "updatedDate",
    render: (date) => dateToString(date),
    width: "8%",
  },
  {
    title: "부품타입",
    dataIndex: "type",
    width: "8%",
  },
  {
    title: "부품명",
    dataIndex: "name",
    width: "25%",
  },
  {
    title: "이미지 크기 비율",
    dataIndex: "scale",
    width: "40%",
  },
];

export default function List() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [parts, setParts] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: (record) => ({}),
  };

  const addPart = (e) => {
    navigate(`/point/create`);
  };

  const deletePart = async (e) => {
    e.preventDefault();

    if (selectedItems.length === 0) {
      message.error("선택된 항목이 없습니다.");
      return;
    }

    setLoading(true);

    await api
      .deletePartToPoints({
        ids: JSON.stringify(selectedItems.map((i) => i.id)),
      })
      .then((res) => {
        const { linusCode } = res.data;

        if (linusCode === 1) {
          setSelectedItems([]);
          message.success("성공적으로 삭제되었습니다.");
        }
        setLoading(false);
      })
      .then(() => {
        fetchPartToPoint();
      })
      .catch(errorHandler);

    setLoading(false);
  };

  const fetchPartToPoint = async () => {
    setLoading(true);
    await api
      .getPartToPoints({
        page: activePage,
        size: 10,
      })
      .then((res) => {
        const { linusCode, page, data } = res.data;
        if (linusCode === 1) {
          setTotalItemsCount(page.count ?? 0);
          setParts(
            data.map((item) => {
              return {
                ...item,
                key: item.id,
                type: item?.part?.type,
                name: item?.part?.name,
                createDate: dateToString(item.createDate),
              };
            })
          );
        }
      })
      .catch(errorHandler);

    setLoading(false);
  };

  useEffect(() => {
    fetchPartToPoint();
  }, [activePage]);

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="부품 좌표 목록"
        subTitle={`총 ${totalItemsCount}개의 부품 좌표 정보가 존재합니다.`}
      />
      <FlexRowReverseContainer>
        <Button danger onClick={deletePart} style={{ margin: "0 10px" }}>
          부품 좌표 정보 삭제
        </Button>
        <Button type="primary" onClick={addPart}>
          부품 좌표 정보 추가
        </Button>
      </FlexRowReverseContainer>
      <Divider />
      <Table
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={parts}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/point/${record.id}`);
            },
          };
        }}
      />

      <FlexRowEndContainer style={{ marginTop: "30px" }}>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={10}
          onChange={(page) => setActivePage(page)}
        />
      </FlexRowEndContainer>
    </>
  );
}
