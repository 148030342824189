import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  PageHeader,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import * as api from "../../apis/user";
import { useEffect, useState } from "react";
import { errorHandler } from "src/utils/eventErrorHandler";

export default function Detail() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const params = useParams();

  const [pageTitle, setPageTitle] = useState("");

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const onFinish = (values) => {
    editUser(values);
  };

  const deleteUser = () => {
    setLoading(true);

    api
      .deleteUser({ id: parseInt(params.id, 10) })
      .then((res) => {
        const { linusCode } = res.data;

        if (linusCode === 1) {
          message.success("회원 정보가 삭제 되었습니다.");
          navigate(-1);
          setLoading(false);
        }
      })
      .catch(errorHandler);
  };

  const editUser = (values) => {
    setLoading(true);

    api
      .putUser({ id: params.id, ...values })
      .then((res) => {
        const { linusCode } = res.data;
        if (linusCode === 1) {
          message.success("회원 정보가 수정되었습니다.");
          navigate(-1);
          setLoading(false);
        }
      })
      .catch(errorHandler);
  };

  const fetchUser = () => {
    setLoading(true);

    api.getUser({ id: params.id }).then((res) => {
      const { linusCode, data } = res.data;

      if (linusCode === 1) {
        setUser(data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setPageTitle("회원정보 상세");
    fetchUser();
  }, []);

  return (
    <div>
      <PageHeader className="site-page-header" title={pageTitle} />
      <Divider />
      {!loading && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={user}
              scrollToFirstError
              style={{ width: "50%" }}
            >
              <Form.Item
                name="phone"
                label=" 핸드폰 번호"
                rules={[
                  {
                    required: true,
                    message: "핸드폰 번호를 입력해주세요.",
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                label=" 이메일 주소"
                rules={[
                  {
                    required: true,
                    message: "이메일 주소를 입력해주세요.",
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="password"
                label="패스워드"
                rules={[
                  {
                    required: true,
                    message: "패스워드를 입력해주세요.",
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="name" label="유저 이름">
                <Input />
              </Form.Item>

              <Form.Item name="agreeUsage" valuePropName="checked">
                <Checkbox>개인정보 취급 동의</Checkbox>
              </Form.Item>

              <Form.Item name="agreePrivacy" valuePropName="checked">
                <Checkbox>개인정보 수집 동의</Checkbox>
              </Form.Item>

              <Form.Item name="is_active" valuePropName="checked">
                <Checkbox>활성/비활성</Checkbox>
              </Form.Item>

              <Form.Item>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    목록
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<CheckOutlined />}
                    style={{ margin: "0 10px" }}
                  >
                    저장
                  </Button>
                  <Button
                    type="danger"
                    icon={<DeleteOutlined />}
                    onClick={() => deleteUser()}
                  >
                    삭제
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </div>
  );
}
