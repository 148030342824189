import client from "./client";

export const getResults = ({ title, ordering, page, size, isOnlyCount, startCreatedDate, endCreatedDate }) => {
  return client.get("/share-data/", {
    params: { title, ordering, page, size, isOnlyCount, startCreatedDate, endCreatedDate },
  });
};

export const excelDownload = ({ startCreatedDate, endCreatedDate }) => {
  return client.get("/share-data/", {
    params: { isExcel: 1, startCreatedDate, endCreatedDate },
    responseType: "arraybuffer"
  });
};
