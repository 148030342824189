import client from "./client";

export const getParts = ({ ordering, page, size, isOnlyCount }) => {
  return client.get("/part/", {
    params: { ordering, page, size, isOnlyCount },
  });
};

export const getPart = ({ id }) => client.get(`/part/${id}`);

export const postPart = ({ name, type }) =>
  client.post(`/part/`, { name, type });

export const putPart = ({ id, name, type }) =>
  client.put(`/part/${id}/`, { name, type });

export const deleteParts = ({ ids }) => {
  return client.delete(`/part-multi-delete/`, {
    data: { ids },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const postPartImage = ({ id, body }) => {
  return client.post(`/part/${id}/image/`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getPartSets = ({
  userPhone,
  userEmail,
  userName,
  name,
  isDeleted,
  type,
  ordering,
  page,
  size,
  isOnlyCount,
  isMy,
}) => {
  return client.get("/part-set/", {
    params: {
      userPhone,
      userEmail,
      userName,
      name,
      isDeleted,
      type,
      ordering,
      page,
      size,
      isOnlyCount,
      isMy,
    },
  });
};

export const getPartSet = ({ id }) => client.get(`/part-set/${id}`);

export const postPartSet = ({ name, type, partToPoints, incompatibles }) =>
  client.post(`/part-set/`, { name, type, partToPoints, incompatibles });

export const putPartSet = ({ id, name, type, partToPoints, incompatibles }) =>
  client.put(`/part-set/${id}/`, { name, type, partToPoints, incompatibles });

export const deletePartSets = ({ ids }) => {
  return client.delete(`part-set-multi-delete/`, {
    data: { ids },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getPartToPoints = ({
  isDeleted,
  userPhone,
  userEmail,
  userName,
  partName,
  ordering,
  page,
  size,
  isOnlyCount,
  isMy,
}) => {
  return client.get("/part-to-point/", {
    params: {
      isDeleted,
      userPhone,
      userEmail,
      userName,
      partName,
      ordering,
      page,
      size,
      isOnlyCount,
      isMy,
    },
  });
};

export const getPartToPoint = ({ id }) => client.get(`/part-to-point/${id}`);

export const postPartToPoint = ({
  id,
  scale,
  angle,
  part,
  partName,
  partType,
  partUrl,
  barsToFramesetsX,
  barsToFramesetsY,
  barsToLeversX,
  barsToLeversY,
  framesetsToBarsX,
  framesetsToBarsY,
  framesetsToLeversX,
  framesetsToLeversY,
  framesetsToSaddlesX,
  framesetsToSaddlesY,
  framesetsToSeatpostsX,
  framesetsToSeatpostsY,
  framesetsToCranksetX,
  framesetsToCranksetY,
  framesetsToFrontwheelX,
  framesetsToFrontwheelY,
  framesetsToRearwheelX,
  framesetsToRearwheelY,
  framesetsToFrontmechX,
  framesetsToFrontmechY,
  framesetsToRearmechX,
  framesetsToRearmechY,
  cranksetToFramesetsX,
  cranksetToFramesetsY,
  cassetteToFramesetsX,
  cassetteToFramesetsY,
  frontrotorToFramesetsX,
  frontrotorToFramesetsY,
  frontrotorToFrontcaliperX,
  frontrotorToFrontcaliperY,
  rearrotorToFramesetsX,
  rearrotorToFramesetsY,
  rearrotorToRearcaliperX,
  rearrotorToRearcaliperY,
  frontmechToFramesetsX,
  frontmechToFramesetsY,
  rearmechToFramesetsX,
  rearmechToFramesetsY,
  frontcaliperToFrontrotorX,
  frontcaliperToFrontrotorY,
  rearcaliperToRearrotorX,
  rearcaliperToRearrotorY,
  leverTobarsX,
  leverTobarsY,
  saddlesToFramesetsX,
  saddlesToFramesetsY,
  fronttyresToFramesetsX,
  fronttyresToFramesetsY,
  reartyresToFramesetsX,
  reartyresToFramesetsY,
  frontwheelToFramesetsX,
  frontwheelToFramesetsY,
  rearwheelToFramesetsX,
  rearwheelToFramesetsY,
  bg,
}) => {
  return client.post(`/part-to-point/`, {
    id,
    scale,
    angle,
    part,
    partName,
    partType,
    partUrl,
    barsToFramesetsX,
    barsToFramesetsY,
    barsToLeversX,
    barsToLeversY,
    framesetsToBarsX,
    framesetsToBarsY,
    framesetsToLeversX,
    framesetsToLeversY,
    framesetsToSaddlesX,
    framesetsToSaddlesY,
    framesetsToSeatpostsX,
    framesetsToSeatpostsY,
    framesetsToCranksetX,
    framesetsToCranksetY,
    framesetsToFrontwheelX,
    framesetsToFrontwheelY,
    framesetsToRearwheelX,
    framesetsToRearwheelY,
    framesetsToFrontmechX,
    framesetsToFrontmechY,
    framesetsToRearmechX,
    framesetsToRearmechY,
    cranksetToFramesetsX,
    cranksetToFramesetsY,
    cassetteToFramesetsX,
    cassetteToFramesetsY,
    frontrotorToFramesetsX,
    frontrotorToFramesetsY,
    frontrotorToFrontcaliperX,
    frontrotorToFrontcaliperY,
    rearrotorToFramesetsX,
    rearrotorToFramesetsY,
    rearrotorToRearcaliperX,
    rearrotorToRearcaliperY,
    frontmechToFramesetsX,
    frontmechToFramesetsY,
    rearmechToFramesetsX,
    rearmechToFramesetsY,
    frontcaliperToFrontrotorX,
    frontcaliperToFrontrotorY,
    rearcaliperToRearrotorX,
    rearcaliperToRearrotorY,
    leverTobarsX,
    leverTobarsY,
    saddlesToFramesetsX,
    saddlesToFramesetsY,
    fronttyresToFramesetsX,
    fronttyresToFramesetsY,
    reartyresToFramesetsX,
    reartyresToFramesetsY,
    frontwheelToFramesetsX,
    frontwheelToFramesetsY,
    rearwheelToFramesetsX,
    rearwheelToFramesetsY,
    bg,
  });
};

export const putPartToPoint = ({
  id,
  scale,
  angle,
  part,
  partName,
  partType,
  partUrl,
  barsToFramesetsX,
  barsToFramesetsY,
  barsToLeversX,
  barsToLeversY,
  framesetsToBarsX,
  framesetsToBarsY,
  framesetsToLeversX,
  framesetsToLeversY,
  framesetsToSaddlesX,
  framesetsToSaddlesY,
  framesetsToSeatpostsX,
  framesetsToSeatpostsY,
  framesetsToCranksetX,
  framesetsToCranksetY,
  framesetsToFrontwheelX,
  framesetsToFrontwheelY,
  framesetsToRearwheelX,
  framesetsToRearwheelY,
  framesetsToFrontmechX,
  framesetsToFrontmechY,
  framesetsToRearmechX,
  framesetsToRearmechY,
  cranksetToFramesetsX,
  cranksetToFramesetsY,
  cassetteToFramesetsX,
  cassetteToFramesetsY,
  frontrotorToFramesetsX,
  frontrotorToFramesetsY,
  frontrotorToFrontcaliperX,
  frontrotorToFrontcaliperY,
  rearrotorToFramesetsX,
  rearrotorToFramesetsY,
  rearrotorToRearcaliperX,
  rearrotorToRearcaliperY,
  frontmechToFramesetsX,
  frontmechToFramesetsY,
  rearmechToFramesetsX,
  rearmechToFramesetsY,
  frontcaliperToFrontrotorX,
  frontcaliperToFrontrotorY,
  rearcaliperToRearrotorX,
  rearcaliperToRearrotorY,
  leverTobarsX,
  leverTobarsY,
  saddlesToFramesetsX,
  saddlesToFramesetsY,
  fronttyresToFramesetsX,
  fronttyresToFramesetsY,
  reartyresToFramesetsX,
  reartyresToFramesetsY,
  frontwheelToFramesetsX,
  frontwheelToFramesetsY,
  rearwheelToFramesetsX,
  rearwheelToFramesetsY,
  bg,
}) =>
  client.put(`/part-to-point/${id}/`, {
    scale,
    angle,
    part,
    partName,
    partType,
    partUrl,
    barsToFramesetsX,
    barsToFramesetsY,
    barsToLeversX,
    barsToLeversY,
    framesetsToBarsX,
    framesetsToBarsY,
    framesetsToLeversX,
    framesetsToLeversY,
    framesetsToSaddlesX,
    framesetsToSaddlesY,
    framesetsToSeatpostsX,
    framesetsToSeatpostsY,
    framesetsToCranksetX,
    framesetsToCranksetY,
    framesetsToFrontwheelX,
    framesetsToFrontwheelY,
    framesetsToRearwheelX,
    framesetsToRearwheelY,
    framesetsToFrontmechX,
    framesetsToFrontmechY,
    framesetsToRearmechX,
    framesetsToRearmechY,
    cranksetToFramesetsX,
    cranksetToFramesetsY,
    cassetteToFramesetsX,
    cassetteToFramesetsY,
    frontrotorToFramesetsX,
    frontrotorToFramesetsY,
    frontrotorToFrontcaliperX,
    frontrotorToFrontcaliperY,
    rearrotorToFramesetsX,
    rearrotorToFramesetsY,
    rearrotorToRearcaliperX,
    rearrotorToRearcaliperY,
    frontmechToFramesetsX,
    frontmechToFramesetsY,
    rearmechToFramesetsX,
    rearmechToFramesetsY,
    frontcaliperToFrontrotorX,
    frontcaliperToFrontrotorY,
    rearcaliperToRearrotorX,
    rearcaliperToRearrotorY,
    leverTobarsX,
    leverTobarsY,
    saddlesToFramesetsX,
    saddlesToFramesetsY,
    fronttyresToFramesetsX,
    fronttyresToFramesetsY,
    reartyresToFramesetsX,
    reartyresToFramesetsY,
    frontwheelToFramesetsX,
    frontwheelToFramesetsY,
    rearwheelToFramesetsX,
    rearwheelToFramesetsY,
    bg,
  });

export const deletePartToPoints = ({ ids }) => {
  return client.delete(`/part-to-point-multi-delete/`, {
    data: { ids },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getBgs = ({ ordering, page, size, isOnlyCount, isDeleted }) => {
  return client.get("/bg/", {
    params: { ordering, page, size, isOnlyCount, isDeleted },
  });
};

export const getBg = ({ id }) => client.get(`/bg/${id}`);
