import React from "react";
import { Route, Routes } from "react-router-dom";
import List from "../../components/product/partset/List";
import Form from "../../components/product/partset/Form";

export default function ProductPartsetPage() {
  return (
    <Routes>
      <Route path="/*" element={<List />} />
      <Route path="/:id" element={<Form />} />
    </Routes>
  );
}
