import React, { useEffect, useState } from "react";
import { Table, Divider, PageHeader } from "antd";

import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";

import { FlexRowEndContainer } from "../common/Container.styles";

import { dateToString } from "../../utils/common";
import * as api from "../../apis/survey";
import { errorHandler } from "src/utils/eventErrorHandler";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    width: "5%",
  },
  {
    title: "등록일",
    dataIndex: "createdDate",
    render: (date) => dateToString(date),
    width: "20%",
  },
  {
    title: "응답",
    dataIndex: "title",
    width: "*",
  },
];

export default function List() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const fetchResults = async () => {
    setLoading(true);

    await api
      .getResults({
        page: activePage,
        size: 10,
      })
      .then((res) => {
        const { linusCode, page, data } = res.data;

        if (linusCode === 1) {
          setTotalItemsCount(page.count ?? 0);
          setResult(
            data.map((item) => {
              return {
                ...item,
                key: item.id,
                createDate: dateToString(item.createDate),
              };
            })
          );
        }
        setLoading(false);
      })
      .catch(errorHandler);
  };

  useEffect(() => {
    fetchResults();
  }, [activePage]);

  return (
    <>
      <PageHeader className="site-page-header" title="자전거 추천 결과 목록" />
      <Divider />
      <Table
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={result}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/survey/${record.id}`);
            },
          };
        }}
      />

      <FlexRowEndContainer style={{ marginTop: "30px" }}>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={10}
          onChange={(page) => setActivePage(page)}
        />
      </FlexRowEndContainer>
    </>
  );
}
