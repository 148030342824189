import React from "react";
import { Route, Routes } from "react-router-dom";
import List from "../../components/product/point/List";
import Form from "../../components/product/point/Form";

export default function ProductPartToPointPage() {
  return (
    <Routes>
      <Route path="/*" element={<List />} />
      <Route path="/:id" element={<Form />} />
    </Routes>
  );
}
