import {
  Button,
  Divider,
  Form,
  Input,
  message,
  PageHeader,
  Upload,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  CheckOutlined,
  CloseOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import * as api from "../../apis/banner";
import { useEffect, useState } from "react";
import { errorHandler } from "src/utils/eventErrorHandler";

export default function Detail() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const params = useParams();

  const [isCreatePage, setIsCreatePage] = useState(true);
  const [pageTitle, setPageTitle] = useState("");

  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState(null);
  const [fileList, setFileList] = useState([]);

  // const uploadProps = {
  //   name: "files",
  //   beforeUpload: (file) => {
  //     setFileList(() => [file]);
  //     return false; // 파일 선택시 바로 업로드 하지 않고 후에 한꺼번에 전송하기 위함
  //   },
  //   fileList,
  // };

  const uploadProps = {
    name: "image",
    action: `https://info.birect.co.kr/api/v1/web_admin/banner/${params.id}/image/`,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      setFileList(() => [info.file]);
    },
    fileList,
  };

  const onFinish = async (values) => {
    if (isCreatePage) {
      createBanner(values);
    } else {
      await editBanner(values);
    }
  };

  // const uploadImage = () => {
  //   api.postBannerImage({ id: params.id, image: fileList[0] });
  // };

  const createBanner = async (values) => {
    await api
      .postBanner({ ...values })
      .then((res) => {
        const { linusCode } = res.data;

        if (linusCode === 1) {
          message.success("배너가 추가되었습니다.");
          navigate(-1);
        }
      })
      .catch(errorHandler);
  };

  const editBanner = async (values) => {
    await api
      .putBanner({ ...banner, ...values })
      .then((res) => {
        const { linusCode } = res.data;

        if (linusCode === 1) {
          message.success("배너가 수정되었습니다.");
          navigate(-1);
        }
      })
      .catch(errorHandler);
  };

  const fetchBanner = async () => {
    setLoading(true);

    await api
      .getBanner({ id: params.id })
      .then((res) => {
        const { linusCode, data } = res.data;

        if (linusCode === 1) {
          setBanner(data);
          setFileList((object) => [{ ...object, name: data.image }]);
        }
        setLoading(false);
      })
      .catch(errorHandler);
  };

  useEffect(() => {
    setIsCreatePage(params.id === "create");
  }, []);

  useEffect(() => {
    if (isCreatePage) {
      setPageTitle("배너 생성");
    } else {
      setPageTitle("배너 수정");
      fetchBanner();
    }
  }, [isCreatePage]);

  return (
    <div>
      <PageHeader className="site-page-header" title={pageTitle} />
      <Divider />
      {!loading && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={banner}
              scrollToFirstError
              style={{ width: "50%" }}
            >
              <Form.Item
                name="name"
                label="배너 명칭"
                rules={[
                  {
                    required: true,
                    message: "배너 명칭을 입력해주세요.",
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {isCreatePage ? null : (
                <Form.Item name="image" label="배너 이미지">
                  <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>
              )}

              {isCreatePage ? null : (
                <Form.Item name="index" label="정렬 순서">
                  <Input />
                </Form.Item>
              )}

              <Form.Item
                name="url"
                label="배너 주소"
                rules={[
                  {
                    required: true,
                    message: "배너 주소를 입력해주세요.",
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    htmlType="submit"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                    style={{ margin: "0 10px" }}
                  >
                    목록
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<CheckOutlined />}
                  >
                    저장
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </div>
  );
}
