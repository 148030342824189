import client from "./client";

export const getNotices = ({
  title,
  content,
  ordering,
  page,
  size,
  isOnlyCount,
}) => {
  return client.get("/notice/", {
    params: { title, content, ordering, page, size, isOnlyCount },
  });
};

export const getNotice = ({ id }) => client.get(`/notice/${id}`);

export const postNotice = ({ title, content }) =>
  client.post(`/notice/`, { title, content });

export const putNotice = ({ id, title, content }) =>
  client.put(`/notice/${id}/`, { title, content });

export const deleteNotices = ({ ids }) => {
  return client.delete(`/notice-multi-delete/`, {
    data: { ids },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
