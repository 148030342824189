import client from "./client";

export const getBanners = ({ ordering, page, size, isOnlyCount }) => {
  return client.get("/banner/", {
    params: {
      ordering,
      page,
      size,
      isOnlyCount,
    },
  });
};

export const getBanner = ({ id }) => client.get(`/banner/${id}`);

export const postBanner = ({ name, index, url }) =>
  client.post("/banner/", { name, index, url });

export const putBanner = ({ id, name, index, url }) =>
  client.put(`/banner/${id}/`, { name, index, url });

export const deleteBanner = ({ id }) => client.delete(`/banner/${id}`);

export const deleteBanners = ({ ids }) => {
  return client.delete(`/banner-multi-delete/`, {
    data: { ids },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const postBannerImage = ({ id, image }) => {
  return client.post(
    `/banner/${id}/image/`,
    {
      image: image,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
