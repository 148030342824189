import client from "./client";

export const getBgs = ({ ordering, page, size, isOnlyCount }) => {
  return client.get("/bg/", {
    params: {
      ordering,
      page,
      size,
      isOnlyCount,
    },
  });
};

export const getBg = ({ id }) => client.get(`/bg/${id}`);

export const postBg = ({ name, index, url }) =>
  client.post("/bg/", { name, index, url });

export const putBg = ({ id, name, index, url }) =>
  client.put(`/bg/${id}/`, { name, index, url });

export const deleteBg = ({ id }) => client.delete(`/bg/${id}`);

export const deleteBgs = ({ ids }) => {
  return client.delete(`/bg-multi-delete/`, {
    data: { ids },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const postBgImage = ({ id, image }) => {
  return client.post(
    `/bg/${id}/image/`,
    {
      image: image,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
