import React from "react";
import { Route, Routes } from "react-router-dom";
import List from "../../components/banner/List";
import Form from "../../components/banner/Form";

export default function NoticePage() {
  return (
    <Routes>
      <Route path="/*" element={<List />} />
      <Route path="/:id" element={<Form />} />
    </Routes>
  );
}
