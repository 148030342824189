import client from "./client";

export const getResults = ({ title, ordering, page, size, isOnlyCount, startCreatedDate, endCreatedDate }) => {
  return client.get("/user-survey-data/", {
    params: { title, ordering, page, size, isOnlyCount, startCreatedDate, endCreatedDate },
  });
};

export const excelDownload = ({ startCreatedDate, endCreatedDate }) => {
  return client.get("/user-survey-data/", {
    params: { is_excel: 1, startCreatedDate, endCreatedDate },
    responseType: "arraybuffer"
  });
};

