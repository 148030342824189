import React from "react";
import { Route, Routes } from "react-router-dom";
import List from "../../components/survey-data/List";

export default function SurveyPage() {
  return (
    <Routes>
      <Route path="/*" element={<List />} />
    </Routes>
  );
}
