import {
  Button,
  Divider,
  Form,
  Input,
  message,
  PageHeader,
  Select,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import * as api from "../../apis/qna";
import { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { errorHandler } from "src/utils/eventErrorHandler";

export default function Detail() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const params = useParams();

  const [isCreatePage, setIsCreatePage] = useState(true);
  const [pageTitle, setPageTitle] = useState("");

  const [loading, setLoading] = useState(false);
  const [qna, setQna] = useState(null);

  const onFinish = (values) => {
    if (isCreatePage) {
      createQna(values);
    } else {
      editQna(values);
    }
  };

  const createQna = async (values) => {
    setLoading(true);

    await api
      .postQna({ ...values })
      .then((res) => {
        const { linusCode } = res.data;
        if (linusCode === 1) {
          message.success("문의 내용이 추가되었습니다.");
          navigate(-1);
        }
        setLoading(false);
      })
      .catch(errorHandler);
  };

  const editQna = (values) => {
    api
      .putQna({ ...qna, ...values })
      .then((res) => {
        const { linusCode } = res.data;
        if (linusCode === 1) {
          message.success("문의 내용이 수정되었습니다.");
          navigate(-1);
        }
      })
      .catch(errorHandler);
  };

  const fetchQna = () => {
    setLoading(true);

    api.getQna({ id: params.id }).then((res) => {
      const { linusCode, data } = res.data;

      if (linusCode === 1) {
        setQna(data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setIsCreatePage(params.id === "create");
  }, []);

  useEffect(() => {
    if (isCreatePage) {
      setPageTitle("1:1문의 내역 생성");
    } else {
      setPageTitle("1:1문의 내역 수정");
      fetchQna();
    }
  }, [isCreatePage]);

  return (
    <div>
      <PageHeader className="site-page-header" title={pageTitle} />
      <Divider />
      {!loading && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={qna}
              scrollToFirstError
              style={{ width: "50%" }}
            >
              <Form.Item
                name="title"
                label="문의 제목"
                rules={[
                  {
                    required: true,
                    message: "제목을 입력해주세요.",
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="content"
                label="문의 내용"
                rules={[
                  {
                    required: true,
                    message: "내용을 입력해주세요.",
                    whitespace: true,
                  },
                ]}
              >
                <TextArea rows={10} />
              </Form.Item>
              <Form.Item
                name="status"
                label="문의 상태"
                rules={[
                  {
                    required: true,
                    message: "상태를 선택해주세요.",
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  style={{ width: 120 }}
                  options={[
                    { value: "확인 중", label: "확인 중" },
                    { value: "답변 완료", label: "답변 완료" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="answer"
                label="답변 내용"
                rules={[
                  {
                    required: true,
                    message: "내용을 입력해주세요.",
                    whitespace: true,
                  },
                ]}
              >
                <TextArea rows={10} />
              </Form.Item>

              <Form.Item>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    htmlType="submit"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                    style={{ margin: "0 10px" }}
                  >
                    목록
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<CheckOutlined />}
                  >
                    저장
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </div>
  );
}
