import React, { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Breadcrumb,
  Button,
  Modal,
  Form,
  Input,
  message,
} from "antd";
import {
  DashboardOutlined,
  UserOutlined,
  FileImageOutlined,
  BellOutlined,
  SettingOutlined,
  AuditOutlined,
  LikeOutlined,
  FileDoneOutlined,
  FileExcelOutlined
} from "@ant-design/icons";
import { menus } from "./constants/menus";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import UserPage from "./pages/user/UserPage";
import NoticePage from "./pages/notice/NoticePage";
import BannerPage from "./pages/banner/BannerPage";
import FaqPage from "./pages/faq/FaqPage";
import QnaPage from "./pages/qna/QnaPage";
import ProductPartsetPage from "./pages/product/ProductPartsetPage";
import ProductBgPage from "./pages/product/ProductBgPage";
import SurveyPage from "./pages/survey/SurveyPage";
import ShareDataPage from "./pages/share-data/ShareDataPage";
import SurveyDataPage from "./pages/survey-data/SurveyDataPage";

import "./index.css";
import "antd/dist/antd.min.css";

import * as api from "./apis/user";
import ProductPartToPointPage from "./pages/product/ProductPartToPointPage";

const { Header, Content, Sider } = Layout;

function Main() {
  const location = useLocation();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const email = Form.useWatch("email", form);
  const password = Form.useWatch("password", form);

  const siderItems = [
    DashboardOutlined,
    UserOutlined,
    FileImageOutlined,
    SettingOutlined,
    SettingOutlined,
    SettingOutlined,
    LikeOutlined,
    BellOutlined,
    AuditOutlined,
    FileDoneOutlined,
    FileExcelOutlined,
    FileExcelOutlined,
  ]?.map((icon, index) => {
    return {
      key: `${menus[index].mainMenu.title}`,
      icon: React.createElement(icon),
      label: `${menus[index].mainMenu.title}`,
      onClick: () => onClickMenu(menus[index].mainMenu, index),
    };
  });

  const [selectedMenu, setSelectedMenu] = useState({
    title: "대시보드",
    path: "",
  });

  const [isAuthed, setIsAuthed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const onClickMenu = (mainMenu, mainIndex) => {
    setSelectedMenu(mainMenu);

    localStorage.setItem("page", 1);
    localStorage.setItem("query", JSON.stringify({}));
    navigate(`/${mainMenu.path}`);
  };

  const onClickLogin = (e) => {
    e.preventDefault();
    setVisible(true);
  };

  const handleOk = (values) => {
    setConfirmLoading(true);

    api
      .login({ email, password })
      .then((res) => {
        const { linusCode, data } = res.data;
        const { accessToken } = data.tokens;

        if (linusCode === 1) {
          message.success("성공적으로 로그인되었습니다.");
          localStorage.setItem("accessToken", accessToken);
          setIsAuthed(true);
          setVisible(false);
        } else {
          message.error(data.msg);
          setIsAuthed(false);
        }
      })
      .catch((error) => {
        message.error("오류가 발생했습니다.");
      });
  };

  const onClickLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("accessToken");
    setIsAuthed(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      message.error("로그인이 필요합니다.");
      return;
    }

    api
      .authCheck({ accessToken })
      .then((res) => {
        if (res.status === 200) {
          setIsAuthed(true);
        }
      })
      .catch((error) => {
        message.error("로그인이 필요합니다.");
        localStorage.removeItem("accessToken");
        setIsAuthed(false);
        setVisible(true);
      });
  }, []);

  useEffect(() => {
    if (location.pathname !== "/") {
      let mainPath = location.pathname.split("/")[1];

      let menu = menus.filter((m) => m.mainMenu.path === mainPath)[0];
      let mainMenu = menu.mainMenu;

      setSelectedMenu(mainMenu);
    }
  }, [location]);

  return (
    <Layout>
      <Modal
        width={400}
        title={`로그인`}
        open={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            취소
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            확인
          </Button>,
        ]}
      >
        <Form form={form}>
          <Form.Item name="email" label="아이디">
            <Input placeholder="아이디를 입력하세요" />
          </Form.Item>
          <Form.Item name="password" label="패스워드">
            <Input placeholder="패스워드를 입력하세요" type={"password"} />
          </Form.Item>
        </Form>
      </Modal>
      <Header
        className="header"
        style={{
          display: "flex",
        }}
      >
        <div
          className="logo"
          style={{
            color: "white",
            fontWeight: 800,
            fontSize: "1.5em",
            cursor: "pointer",
            display: "flex",
            width: "50%",
          }}
          onClick={() => {
            setSelectedMenu({
              title: "대시보드",
              path: "",
            });
            navigate("/");
          }}
        >
          바이렉트 관리자
        </div>
        <div
          className="logo"
          style={{
            color: "white",
            fontWeight: 800,
            fontSize: "1.5em",
            cursor: "pointer",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            width: "50%",
          }}
        >
          {!isAuthed && (
            <Button ghost onClick={onClickLogin}>
              로그인
            </Button>
          )}
          {isAuthed && (
            <Button ghost onClick={onClickLogout}>
              로그아웃
            </Button>
          )}
        </div>
      </Header>
      <Layout>
        <Sider width={200} className="site-layout-background">
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{
              height: "100%",
              borderRight: 0,
            }}
            items={siderItems}
          />
        </Sider>
        <Layout>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: "calc(100vh - 64px)",
            }}
          >
            <Breadcrumb style={{ margin: "0 0 30px" }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>{selectedMenu?.title}</Breadcrumb.Item>
            </Breadcrumb>

            <Routes>
              <Route path="/" element={isAuthed ? <UserPage /> : <></>} />
              <Route path="/user/*" element={isAuthed ? <UserPage /> : <></>} />
              <Route
                path="/notice/*"
                element={isAuthed ? <NoticePage /> : <></>}
              />
              <Route
                path="/banner/*"
                element={isAuthed ? <BannerPage /> : <></>}
              />
              <Route path="/faq/*" element={isAuthed ? <FaqPage /> : <></>} />
              <Route path="/qna/*" element={isAuthed ? <QnaPage /> : <></>} />
              <Route
                path="/partset/*"
                element={isAuthed ? <ProductPartsetPage /> : <></>}
              />
              <Route
                path="/point/*"
                element={isAuthed ? <ProductPartToPointPage /> : <></>}
              />
              <Route
                path="/bg/*"
                element={isAuthed ? <ProductBgPage /> : <></>}
              />
              <Route
                path="/survey/*"
                element={isAuthed ? <SurveyPage /> : <></>}
              />
              <Route
                path="/share-data/*"
                element={isAuthed ? <ShareDataPage /> : <></>}
              />
              <Route
                path="/survey-data/*"
                element={isAuthed ? <SurveyDataPage /> : <></>}
              />
            </Routes>
          </Content>
        </Layout>
      </Layout>
      {/* <Footer>Footer</Footer> */}
    </Layout>
  );
}

export default Main;
