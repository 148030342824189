import {
  Button,
  Divider,
  Form,
  Input,
  message,
  PageHeader,
  Select,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import * as api from "../../../apis/product";
import { useEffect, useState } from "react";
import { errorHandler } from "src/utils/eventErrorHandler";
import Modal from "antd/lib/modal/Modal";

export default function Detail() {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const navigate = useNavigate();
  const params = useParams();

  const [isCreatePage, setIsCreatePage] = useState(true);
  const [pageTitle, setPageTitle] = useState("");

  const [loading, setLoading] = useState(false);

  const [partSet, setPartSet] = useState(null);
  const [parts, setParts] = useState(null);
  const [partSets, setPartSets] = useState(null);

  const [visible, setVisible] = useState(false);

  const handlePart = (e) => {
    // console.log("handlePart", e);
  };

  const handlePartSet = (e) => {
    // console.log("handlePartSet", e);
  };

  const onFinish = (values) => {
    if (isCreatePage) {
      createPart(values);
    } else {
      editPart(values);
    }
  };

  const handleOk = (e) => {
    form2.validateFields().then(async (values) => {
      const { name, type } = values;

      if (name && type) {
        await api.postPart({ name, type }).then(() => {
          setVisible(false);
        });
      }
    });
  };

  const covertIds = (values, key) => {
    const list = values[key];
    const ids = [];

    if (list) {
      list.map((item) => {
        ids.push(item.value);
        return item;
      });
    }

    return ids;
  };

  const createPart = async (values) => {
    await api
      .postPartSet({ ...values, partToPoints: covertIds(values, "parts"), incompatibles: covertIds(values, "incompatibles") })
      .then((res) => {
        const { linusCode } = res.data;
        if (linusCode === 1) {
          message.success("부품 기준 정보 내역이 추가되었습니다.");
          navigate(-1);
        }
      })
      .catch(errorHandler);
  };

  const editPart = async (values) => {
    await api
      .putPartSet({ id: params.id, ...values, partToPoints: covertIds(values, "parts"), incompatibles: covertIds(values, "incompatibles") })
      .then((res) => {
        const { linusCode } = res.data;
        if (linusCode === 1) {
          message.success("부품 기준 정보가 수정되었습니다.");
          navigate(-1);
        }
      })
      .catch(errorHandler);
  };

  const fetchPartSets = async () => {
    await api.getPartSets({}).then((res) => {
      const { linusCode, data } = res.data;

      if (linusCode === 1) {
        setPartSets(
          data.map((item) => {
            return {
              label: item.name,
              value: String(item.id),
            };
          })
        );
      }
    });
  };

  const fetchPartSet = async () => {
    setLoading(true);

    await api.getPartSet({ id: params.id }).then((res) => {
      const { linusCode, data } = res.data;

      if (linusCode === 1) {
        const parts = data.partToPoints.map((item) => {
          return {
            label: item.part.name,
            value: String(item.id),
          };
        });

        const incompatibles = data.incompatibles.map((item) => {
          return {
            label: item.name,
            value: String(item.id),
          };
        });

        setPartSet({
          ...data,
          parts,
          incompatibles,
        });
      }
      setLoading(false);
    });
  };

  const fetchPartToPoint = async () => {
    await api
      .getPartToPoints({
        size: 999,
      })
      .then((res) => {
        const { linusCode, data } = res.data;

        if (linusCode === 1) {
          setParts(
            data.map((item) => {
              return {
                label: item.part?.name,
                value: String(item.id),
              };
            })
          );
        }
      })
      .catch(errorHandler);
  };

  useEffect(() => {
    setIsCreatePage(params.id === "create");
  }, []);

  useEffect(() => {
    if (isCreatePage) {
      setPageTitle("부품 그룹 정보 생성");
    } else {
      setPageTitle("부품 그룹 정보 수정");
      fetchPartSet()
    }
    fetchPartSets();
    fetchPartToPoint();
  }, [isCreatePage]);

  return (
    <div>
      <PageHeader className="site-page-header" title={pageTitle} />
      <Divider />
      {!loading && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={partSet}
              scrollToFirstError
              style={{ width: "50%" }}
            >
              <Form.Item
                name="name"
                label="부품 그룹명"
                rules={[
                  {
                    required: true,
                    message: "부품명을 입력해주세요.",
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="type"
                label="부품 그룹 타입"
                rules={[
                  {
                    required: true,
                    message: "부품 그룹 타입을 선택해주세요.",
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  style={{ width: 120 }}
                  options={[
                    { value: "bars", label: "bars" },
                    { value: "framesets", label: "framesets" },
                    { value: "groupsets", label: "groupsets" },
                    { value: "saddles", label: "saddles" },
                    { value: "tyres", label: "tyres" },
                    { value: "wheels", label: "wheels" },
                  ]}
                />
              </Form.Item>

              <Form.Item name="parts" label="부품">
                <Select
                  labelInValue
                  mode="tags"
                  style={{ width: 300 }}
                  placeholder=""
                  options={parts}
                  onChange={handlePart}
                />
              </Form.Item>

              <Form.Item name="incompatibles" label="호환 불가 부품 그룹">
                <Select
                  labelInValue
                  mode="tags"
                  style={{ width: 300 }}
                  placeholder=""
                  options={partSets}
                  onChange={handlePartSet}
                />
              </Form.Item>

              {/* <div>
                부품 추가
                <Button
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={handleAdd}
                  style={{ margin: "10px" }}
                />
              </div> */}

              <Modal
                forceRender
                width={400}
                title={`부품 추가`}
                okText={`저장`}
                open={visible}
                onCancel={() => setVisible(false)}
                onOk={handleOk}
              >
                <Form form={form2}>
                  <Form.Item
                    name="type"
                    label="부품타입"
                    rules={[
                      {
                        required: true,
                        message: "부품 타입을 선택해주세요.",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Select
                      style={{ width: 120 }}
                      options={[
                        { value: "bars", label: "bars" },
                        { value: "framesets", label: "framesets" },
                        { value: "crankset", label: "crankset" },
                        { value: "cassette", label: "cassette" },
                        { value: "rotor", label: "rotor" },
                        { value: "mech", label: "mech" },
                        { value: "caliper", label: "caliper" },
                        { value: "lever", label: "lever" },
                        { value: "saddles", label: "saddles" },
                        { value: "tyres", label: "tyres" },
                        { value: "wheel", label: "wheel" },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label="부품명"
                    rules={[
                      {
                        required: true,
                        message: "부품명을 입력해주세요.",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Form>
              </Modal>

              <Form.Item>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    htmlType="submit"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                    style={{ margin: "0 10px" }}
                  >
                    목록
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<CheckOutlined />}
                  >
                    저장
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </div>
  );
}
