import client from "./client";

export const getResults = ({ title, ordering, page, size, isOnlyCount }) => {
  return client.get("/result/", {
    params: { title, ordering, page, size, isOnlyCount },
  });
};

export const getResult = ({ id }) => client.get(`/result/${id}`);

export const putResult = ({
  id,
  title,
  url,
  frame,
  bars,
  crankSet,
  saddle,
  wheel,
  tire,
  desc,
}) =>
  client.put(`/result/${id}/`, {
    title,
    url,
    frame,
    bars,
    crankSet,
    saddle,
    wheel,
    tire,
    desc,
  });
