import client from "./client";

export const getFaqs = ({
  title,
  content,
  ordering,
  page,
  size,
  isOnlyCount,
}) => {
  return client.get("/q-a/", {
    params: { title, content, ordering, page, size, isOnlyCount },
  });
};

export const getFaq = ({ id }) => client.get(`/q-a/${id}`);

export const postFaq = ({ title, content }) =>
  client.post(`/q-a/`, { title, content });

export const putFaq = ({ id, title, content }) =>
  client.put(`/q-a/${id}/`, { title, content });

export const deleteFaqs = ({ ids }) => {
  return client.delete(`/q-a-multi-delete/`, {
    data: { ids },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
