import { Divider, PageHeader, Table } from "antd";

import Pagination from "react-js-pagination";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FlexRowEndContainer } from "../common/Container.styles";

import * as api from "../../apis/user";
import { dateToString } from "../../utils/common";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    width: "10%",
    render: (val) => {
      return val ?? "-";
    },
  },
  {
    title: "휴대폰 번호",
    dataIndex: "phone",
    width: "8%",
    render: (val) => {
      return val ?? "-";
    },
  },
  {
    title: "EMAIL 주소",
    dataIndex: "email",
    width: "*",
    render: (val) => {
      return val ?? "-";
    },
  },
  {
    title: "이름",
    dataIndex: "name",
    width: "8%",
    render: (val) => {
      return val ?? "-";
    },
  },
  {
    title: "활성/비활성",
    dataIndex: "is_active",
    render: (bool) => {
      return bool ? "O" : "X";
    },
    width: "10%",
    align: "center",
  },
];

export default function List() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const fetchUsers = () => {
    setLoading(true);

    api
      .getUsers({
        page: activePage,
        size: 10,
      })
      .then((res) => {
        const { linusCode, page, data } = res.data;

        if (linusCode === 1) {
          setTotalItemsCount(page.count);
          if (data) {
            setUsers(
              data.map((item) => {
                return {
                  ...item,
                  key: item.id,
                  createDate: dateToString(item.createDate),
                };
              })
            );
          }
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    fetchUsers();
  }, [activePage]);

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="회원정보 목록"
        subTitle={`총 ${totalItemsCount}명의 회원이 존재합니다.`}
      />
      <Divider />
      <Table
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={users}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/user/${record.id}`);
            },
          };
        }}
      />
      <FlexRowEndContainer style={{ marginTop: "30px" }}>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={10}
          onChange={(page) => setActivePage(page)}
        />
      </FlexRowEndContainer>
    </>
  );
}
