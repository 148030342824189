import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  PageHeader,
  Row,
  Select,
  Upload,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  CheckOutlined,
  CloseOutlined,
  ToolOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { fabric } from "fabric";

import * as api from "../../../apis/product";
import { useEffect, useRef, useState } from "react";
import { errorHandler } from "src/utils/eventErrorHandler";

export default function Detail() {
  const [form] = Form.useForm();
  const watchPartType = Form.useWatch("partType", form);

  const navigate = useNavigate();
  const params = useParams();
  const formRef = useRef(null);

  const [isCreatePage, setIsCreatePage] = useState(true);
  const [pageTitle, setPageTitle] = useState("");

  const [loading, setLoading] = useState(false);
  const [partToPoint, setPartToPoint] = useState(null);
  const [bgs, setBgs] = useState([]);

  const [canvas, setCanvas] = useState(null);

  const [partImageFabric, setPartImageFabric] = useState(null);
  // 좌표값
  const [pointFabrics, setPointFabrics] = useState([]);

  const [bgImageFabric, setBgImageFabric] = useState(null);

  const [fileList, setFileList] = useState([]);

  const uploadProps = {
    name: "image",
    action: `https://info.birect.co.kr/api/v1/web_admin/part/${partToPoint?.part?.id}/image/`,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);

        setPartToPoint({
          ...partToPoint,
          part: {
            ...partToPoint.part,
            image: info.file.response?.image,
          },
        });
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      setFileList(() => [info.file]);
    },
    fileList,
  };

  const onFinish = (values) => {
    if (isCreatePage) {
      createPartToPoint(values);
    } else {
      editPartToPoint(values);
    }
  };

  const createPartToPoint = (values) => {
    const { partName, partType, partUrl } = values;

    const params = { partName, partType, partUrl };

    api
      .postPartToPoint(params)
      .then((res) => {
        const { linusCode } = res.data;
        if (linusCode === 1) {
          message.success("부품 기준 정보 내역이 추가되었습니다.");
          navigate(-1);
        }
      })
      .catch(errorHandler);
  };

  const editPartToPoint = (values) => {
    const partId = partToPoint?.part?.id;

    api
      .putPartToPoint({ ...partToPoint, ...values, part: partId })
      .then((res) => {
        const { linusCode } = res.data;
        if (linusCode === 1) {
          message.success("부품 기준 정보가 수정되었습니다.");
          navigate(-1);
        }
      })
      .catch(errorHandler);
  };

  const fetchPartToPoint = () => {
    setLoading(true);

    api.getPartToPoint({ id: params.id }).then((res) => {
      const { linusCode, data } = res.data;

      if (linusCode === 1) {
        setPartToPoint({
          ...data,
          bgImageUrl: data.bg?.image,
          bg: String(data.bg?.id || ""),
          partType: data.part?.type,
          partName: data.part?.name,
          partUrl: data.part?.url,
        });
        setFileList((object) => [{ ...object, name: data.part?.image }]);
      }
      setLoading(false);
    });
  };

  const fetchBgs = async () => {
    await api.getBgs({}).then((res) => {
      const { linusCode, data } = res.data;

      if (linusCode === 1) {
        setBgs(
          data.map((item) => {
            return {
              ...item,
              label: item.name,
              value: String(item.id),
            };
          })
        );
      }
    });
  };

  const generateAll = () => {
    generatePoints();
    if (partImageFabric) {
      formRef.current?.setFieldsValue({ scale: partImageFabric.scaleX });
    }
  };

  const generatePoints = () => {
    pointFabrics.map((pointItem) => {
      const circle = pointItem.fabric.getObjects("circle")[0];

      pointItem.points = {
        x:
          circle.left +
          circle.group.left +
          circle.width / 2 -
          partImageFabric.left,
        Y:
          circle.top +
          circle.group.top +
          circle.height / 2 -
          partImageFabric.top,
      };

      const posXName = `${pointItem.name}X`;
      const posYName = `${pointItem.name}Y`;

      formRef.current?.setFieldsValue({ [posXName]: pointItem.points.x });
      formRef.current?.setFieldsValue({ [posYName]: pointItem.points.Y });

      return pointItem;
    });
  };

  const handlePoint = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      createPoint(value);
    } else {
      removePoint(value);
    }
  };

  const removePoint = (pointName) => {
    const existPoint = pointFabrics.filter((item) => item.name === pointName)[0]
      .fabric;
    canvas.remove(existPoint);
    setPointFabrics(pointFabrics.filter((item) => item.name !== pointName));

    const posXName = `${pointName}X`;
    const posYName = `${pointName}Y`;

    formRef.current?.setFieldsValue({ [posXName]: 0 });
    formRef.current?.setFieldsValue({ [posYName]: 0 });
  };

  const createPoint = (pointName) => {
    const c = new fabric.Circle({
      strokeWidth: 5,
      radius: 10,
      fill: "transparent",
      stroke: "#666",
    });

    const t = new fabric.Text(pointName, {
      fontSize: 16,
      left: -10,
      top: -20,
    });

    const g = new fabric.Group([c, t], {
      originX: "center",
      originY: "center",
      opacity: 0.7,
    });

    g.hasBorders = false;
    g.hasControls = false;

    canvas.add(g);

    setPointFabrics((item) => [...item, { name: pointName, fabric: g }]);
  };

  const createCanvas = async () => {
    if (canvas) return;

    const newCanvas = await new fabric.Canvas("canvas", {
      selection: false,
      width: 1280,
      height: 720,
    });

    // const frontwheel0 = await new Promise((resolve, reject) => {
    //   fabric.Image.fromURL("/images/0frontwheel.png", (oImg) => {
    //     oImg.evented = false;
    //     oImg.scale(0.65);
    //     newCanvas.centerObject(oImg);
    //     resolve(oImg);
    //   });
    // });

    // const fronttyre0 = await new Promise((resolve, reject) => {
    //   fabric.Image.fromURL("/images/0tyres.png", (oImg) => {
    //     oImg.evented = false;
    //     oImg.scale(0.68);
    //     newCanvas.centerObject(oImg);
    //     resolve(oImg);
    //   });
    // });

    // const front = await new fabric.Group([frontwheel0, fronttyre0], {
    //   originX: "center",
    //   originY: "center",
    //   opacity: 0,
    //   evented: false,
    // });

    // front.left = 890;
    // front.top = 465;

    // newCanvas.add(front);

    // const rearwheel0 = await new Promise((resolve, reject) => {
    //   fabric.Image.fromURL("/images/0rearwheel.png", (oImg) => {
    //     oImg.evented = false;
    //     oImg.scale(0.65);
    //     newCanvas.centerObject(oImg);
    //     resolve(oImg);
    //   });
    // });

    // const reartyre0 = await new Promise((resolve, reject) => {
    //   fabric.Image.fromURL("/images/0tyres.png", (oImg) => {
    //     oImg.evented = false;
    //     oImg.scale(0.68);
    //     newCanvas.centerObject(oImg);
    //     resolve(oImg);
    //   });
    // });

    // const rear = new fabric.Group([rearwheel0, reartyre0], {
    //   originX: "center",
    //   originY: "center",
    //   opacity: 0,
    //   evented: false,
    // });

    // rear.left = 400;
    // rear.top = 465;

    // newCanvas.add(rear);

    // await new Promise((resolve, reject) => {
    //   fabric.Image.fromURL("/images/0framesets.png", (oImg) => {
    //     oImg.evented = false;
    //     oImg.opacity = 0;
    //     oImg.scale(0.5);
    //     newCanvas.add(oImg).centerObject(oImg).renderAll();
    //     resolve(oImg);
    //   });
    // });

    setTimeout(() => {
      setCanvas(newCanvas);
    }, 1000);  
  };

  const getImage = () => {
    const imageUrl = partToPoint.part?.image ?? "";

    if (!imageUrl) return;

    fabric.Image.fromURL(`https://info.birect.co.kr/${imageUrl}`, (oImg) => {
      oImg.scale(partToPoint.scale ?? 1);

      oImg.setControlVisible("ml", false);
      oImg.setControlVisible("mb", false);
      oImg.setControlVisible("mr", false);
      oImg.setControlVisible("mt", false);

      if (partImageFabric) {
        canvas.remove(partImageFabric);
      }

      canvas.add(oImg).setActiveObject(oImg).renderAll();

      setPartImageFabric(oImg);
    });
  };

  const getBgImage = () => {
    if (!partToPoint.bg) {
      fabric.Image.fromURL("/images/0framesets.png", (oImg) => {
        oImg.evented = false;
        oImg.scale(0.5);
        canvas.add(oImg).sendToBack(oImg).centerObject(oImg).renderAll();

        if (bgImageFabric) {
          canvas.remove(bgImageFabric);
        }

        setBgImageFabric(oImg);
      });
    } else {
      const imageUrl = partToPoint?.bgImageUrl ?? "";

      fabric.Image.fromURL(imageUrl, (oImg) => {
        oImg.evented = false;
        oImg.scale(1);
        canvas.add(oImg).sendToBack(oImg).centerObject(oImg).renderAll();

        if (bgImageFabric) {
          canvas.remove(bgImageFabric);
        }

        setBgImageFabric(oImg);
      });
    }
  };

  const handleBgs = async (e) => {
    await api
      .getBg({ id: e })
      .then((res) => {
        const { linusCode, data } = res.data;
        if (linusCode === 1) {
          if(!data.image) {
            fabric.Image.fromURL("/images/0framesets.png", (oImg) => {
              oImg.evented = false;
              oImg.scale(0.5);
              canvas.add(oImg).sendToBack(oImg).centerObject(oImg).renderAll();
      
              if (bgImageFabric) {
                canvas.remove(bgImageFabric);
              }
      
              setBgImageFabric(oImg);
            });
          } else {
            fabric.Image.fromURL(data.image, (oImg) => {
              oImg.evented = false;
              oImg.scale(1);
              canvas.add(oImg).sendToBack(oImg).centerObject(oImg).renderAll();

              if (bgImageFabric) {
                canvas.remove(bgImageFabric);
              }
  
              setBgImageFabric(oImg);
            });
          }
        }
      })
      .catch(errorHandler);
  };

  useEffect(() => {
    if (canvas && partToPoint) {
      getImage();
      getBgImage();
    }
  }, [canvas, partToPoint]);

  useEffect(() => {
    if (params.id === "create") {
      setPageTitle("부품 좌표 생성");
    } else {
      setPageTitle("부품 좌표 수정");
      fetchPartToPoint();
      fetchBgs()
    }

    createCanvas();
  }, [isCreatePage]);

  useEffect(() => {
    setIsCreatePage(params.id === "create");
  }, [params]);

  return (
    <div>
      <PageHeader className="site-page-header" title={pageTitle} />
      <Divider />

      {!isCreatePage ? (
        <>
          <div
            id="canvas-container"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
              border: "1px solid #ddd",
              background: "#fff",
              margin: "0 0 50px",
              padding: "50px 30px",
            }}
          >
            <canvas
              id="canvas"
              style={{
                width: 1280,
                height: 720,
                border: "1px solid #ddd",
              }}
            />

            <div style={{ margin: "20px 0" }}>
              {partToPoint?.partType === "wheel" ? (
                <>
                  <Checkbox.Group>
                    <Row>
                      <Col>
                        <Checkbox
                          value="frontwheelToFramesets"
                          onChange={handlePoint}
                        >
                          frontwheelToFramesets
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox
                          value="rearwheelToFramesets"
                          onChange={handlePoint}
                        >
                          rearwheelToFramesets
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </>
              ) : null}
              {partToPoint?.partType === "bars" ? (
                <>
                  <Checkbox.Group>
                    <Row>
                      <Col>
                        <Checkbox
                          value="barsToFramesets"
                          onChange={handlePoint}
                        >
                          barsToFramesets
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox value="barsToLevers" onChange={handlePoint}>
                          barsToLevers
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </>
              ) : null}
              {partToPoint?.partType === "framesets" ? (
                <>
                  <Checkbox.Group>
                    <Row>
                      <Col>
                        <Checkbox
                          value="framesetsToBars"
                          onChange={handlePoint}
                        >
                          framesetsToBars
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox
                          value="framesetsToLevers"
                          onChange={handlePoint}
                        >
                          framesetsToLevers
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox
                          value="framesetsToSaddles"
                          onChange={handlePoint}
                        >
                          framesetsToSaddles
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox
                          value="framesetsToSeatposts"
                          onChange={handlePoint}
                        >
                          framesetsToSeatposts
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox
                          value="framesetsToCrankset"
                          onChange={handlePoint}
                        >
                          framesetsToCrankset
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox
                          value="framesetsToFrontwheel"
                          onChange={handlePoint}
                        >
                          framesetsToFrontwheel
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox
                          value="framesetsToRearwheel"
                          onChange={handlePoint}
                        >
                          framesetsToRearwheel
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox
                          value="framesetsToFrontmech"
                          onChange={handlePoint}
                        >
                          framesetsToFrontmech
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox
                          value="framesetsToRearmech"
                          onChange={handlePoint}
                        >
                          framesetsToRearmech
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </>
              ) : null}
              {partToPoint?.partType === "crankset" ? (
                <>
                  <Checkbox.Group>
                    <Row>
                      <Col>
                        <Checkbox
                          value="cranksetToFramesets"
                          onChange={handlePoint}
                        >
                          cranksetToFramesets
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </>
              ) : null}
              {partToPoint?.partType === "cassette" ? (
                <>
                  <Checkbox.Group>
                    <Row>
                      <Col>
                        <Checkbox
                          value="cassetteToFramesets"
                          onChange={handlePoint}
                        >
                          cassetteToFramesets
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </>
              ) : null}
              {partToPoint?.partType === "rotor" ? (
                <>
                  <Checkbox.Group>
                    <Row>
                      <Col>
                        <Checkbox
                          value="frontrotorToFramesets"
                          onChange={handlePoint}
                        >
                          frontrotorToFramesets
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox
                          value="frontrotorToFrontcaliper"
                          onChange={handlePoint}
                        >
                          frontrotorToFrontcaliper
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox
                          value="rearrotorToFramesets"
                          onChange={handlePoint}
                        >
                          rearrotorToFramesets
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox
                          value="rearrotorToRearcaliper"
                          onChange={handlePoint}
                        >
                          rearrotorToRearcaliper
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </>
              ) : null}
              {partToPoint?.partType === "mech" ? (
                <>
                  <Checkbox.Group>
                    <Row>
                      <Col>
                        <Checkbox
                          value="frontmechToFramesets"
                          onChange={handlePoint}
                        >
                          frontmechToFramesets
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox
                          value="rearmechToFramesets"
                          onChange={handlePoint}
                        >
                          rearmechToFramesets
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </>
              ) : null}
              {partToPoint?.partType === "caliper" ? (
                <>
                  <Checkbox.Group>
                    <Row>
                      <Col>
                        <Checkbox
                          value="frontcaliperToFrontrotor"
                          onChange={handlePoint}
                        >
                          frontcaliperToFrontrotor
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox
                          value="rearcaliperToRearrotor"
                          onChange={handlePoint}
                        >
                          rearcaliperToRearrotor
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </>
              ) : null}
              {partToPoint?.partType === "lever" ? (
                <>
                  <Checkbox.Group>
                    <Row>
                      <Col>
                        <Checkbox value="leverTobars" onChange={handlePoint}>
                          leverTobars
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </>
              ) : null}
              {partToPoint?.partType === "saddles" ? (
                <>
                  <Checkbox.Group>
                    <Row>
                      <Col>
                        <Checkbox
                          value="saddlesToFramesets"
                          onChange={handlePoint}
                        >
                          saddlesToFramesets
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </>
              ) : null}
              {partToPoint?.partType === "tyres" ? (
                <>
                  <Checkbox.Group>
                    <Row>
                      <Col>
                        <Checkbox
                          value="fronttyresToFramesets"
                          onChange={handlePoint}
                        >
                          fronttyresToFramesets
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox
                          value="reartyresToFramesets"
                          onChange={handlePoint}
                        >
                          reartyresToFramesets
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </>
              ) : null}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px 0 50px",
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                onClick={generateAll}
                icon={<ToolOutlined />}
              >
                좌표 생성
              </Button>
            </div>
          </div>
        </>
      ) : null}

      {!loading && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form
              ref={formRef}
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={partToPoint}
              scrollToFirstError
              style={{ width: "50%" }}
            >
              <Form.Item
                name="partName"
                label="부품명"
                rules={[
                  {
                    required: true,
                    message: "부품명을 입력해주세요.",
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {isCreatePage ? (
                <Form.Item
                  name="partType"
                  label="부품 타입"
                  rules={[
                    {
                      required: true,
                      message: "부품 타입을 선택해주세요.",
                      whitespace: true,
                    },
                  ]}
                >
                  <Select
                    style={{ width: 300 }}
                    options={[
                      { value: "bars", label: "bars" },
                      { value: "framesets", label: "framesets" },
                      { value: "crankset", label: "crankset" },
                      { value: "cassette", label: "cassette" },
                      { value: "rotor", label: "rotor" },
                      { value: "mech", label: "mech" },
                      { value: "caliper", label: "caliper" },
                      { value: "lever", label: "lever" },
                      { value: "saddles", label: "saddles" },
                      { value: "tyres", label: "tyres" },
                      { value: "wheel", label: "wheel" },
                    ]}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name="partType"
                  label="부품 타입"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <Input readOnly={true} />
                </Form.Item>
              )}

              {!isCreatePage ? (
                <Form.Item name="image" label="부품 이미지">
                  <>
                    <Upload {...uploadProps}>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                  </>
                </Form.Item>
              ) : null}

              {!isCreatePage ? <Form.Item name="bg" label="부품 배경 이미지">
                <Select
                  options={[{ value: "", label: "없음" }].concat(bgs)}
                  onChange={handleBgs}
                />
              </Form.Item> : null}

              <Form.Item
                name="partUrl"
                label="이미지 링크"
                style={{
                  display: watchPartType === "framesets" ? "block" : "none",
                }}
              >
                <Input />
              </Form.Item>

              {!isCreatePage ? (
                <Form.Item name="scale" label="이미지 크기 비율">
                  <Input />
                </Form.Item>
              ) : null}

              {partToPoint?.partType === "framesets" ? (
                <>
                  <Form.Item name="framesetsToBarsX" label="framesetsToBarsX">
                    <Input />
                  </Form.Item>

                  <Form.Item name="framesetsToBarsY" label="framesetsToBarsY">
                    <Input />
                  </Form.Item>
                  <Form.Item name="framesetsToBarsX" label="framesetsToBarsX">
                    <Input />
                  </Form.Item>

                  <Form.Item name="framesetsToBarsY" label="framesetsToBarsY">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="framesetsToCranksetX"
                    label="framesetsToCranksetX"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="framesetsToCranksetY"
                    label="framesetsToCranksetY"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="framesetsToFrontmechX"
                    label="framesetsToFrontmechX"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="framesetsToFrontmechY"
                    label="framesetsToFrontmechY"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="framesetsToFrontwheelX"
                    label="framesetsToFrontwheelX"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="framesetsToFrontwheelY"
                    label="framesetsToFrontwheelY"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="framesetsToLeversX"
                    label="framesetsToLeversX"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="framesetsToLeversY"
                    label="framesetsToLeversY"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="framesetsToRearmechX"
                    label="framesetsToRearmechX"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="framesetsToRearmechY"
                    label="framesetsToRearmechY"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="framesetsToRearwheelX"
                    label="framesetsToRearwheelX"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="framesetsToRearwheelY"
                    label="framesetsToRearwheelY"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="framesetsToSaddlesX"
                    label="framesetsToSaddlesX"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="framesetsToSaddlesY"
                    label="framesetsToSaddlesY"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="framesetsToSeatpostsX"
                    label="framesetsToSeatpostsX"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="framesetsToSeatpostsY"
                    label="framesetsToSeatpostsY"
                  >
                    <Input />
                  </Form.Item>
                </>
              ) : null}
              {partToPoint?.partType === "bars" ? (
                <>
                  <Form.Item name="barsToFramesetsX" label="barsToFramesetsX">
                    <Input />
                  </Form.Item>

                  <Form.Item name="barsToFramesetsY" label="barsToFramesetsY">
                    <Input />
                  </Form.Item>

                  <Form.Item name="barsToLeversX" label="barsToLeversX">
                    <Input />
                  </Form.Item>

                  <Form.Item name="barsToLeversY" label="barsToLeversY">
                    <Input />
                  </Form.Item>
                </>
              ) : null}
              {partToPoint?.partType === "crankset" ? (
                <>
                  <Form.Item
                    name="cranksetToFramesetsX"
                    label="cranksetToFramesetsX"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="cranksetToFramesetsY"
                    label="cranksetToFramesetsY"
                  >
                    <Input />
                  </Form.Item>
                </>
              ) : null}
              {partToPoint?.partType === "cassette" ? (
                <>
                  <Form.Item
                    name="cassetteToFramesetsX"
                    label="cassetteToFramesetsX"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="cassetteToFramesetsY"
                    label="cassetteToFramesetsY"
                  >
                    <Input />
                  </Form.Item>
                </>
              ) : null}
              {partToPoint?.partType === "rotor" ? (
                <>
                  <Form.Item
                    name="frontrotorToFramesetsX"
                    label="frontrotorToFramesetsX"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="frontrotorToFramesetsY"
                    label="frontrotorToFramesetsY"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="frontrotorToFrontcaliperX"
                    label="frontrotorToFrontcaliperX"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="frontrotorToFrontcaliperY"
                    label="frontrotorToFrontcaliperY"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="rearrotorToFramesetsX"
                    label="rearrotorToFramesetsX"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="rearrotorToFramesetsY"
                    label="rearrotorToFramesetsY"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="rearrotorToRearcaliperX"
                    label="rearrotorToRearcaliperX"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="rearrotorToRearcaliperY"
                    label="rearrotorToRearcaliperY"
                  >
                    <Input />
                  </Form.Item>
                </>
              ) : null}
              {partToPoint?.partType === "mech" ? (
                <>
                  <Form.Item
                    name="frontmechToFramesetsX"
                    label="frontmechToFramesetsX"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="frontmechToFramesetsY"
                    label="frontmechToFramesetsY"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="rearmechToFramesetsX"
                    label="rearmechToFramesetsX"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="rearmechToFramesetsY"
                    label="rearmechToFramesetsY"
                  >
                    <Input />
                  </Form.Item>
                </>
              ) : null}
              {partToPoint?.partType === "caliper" ? (
                <>
                  <Form.Item
                    name="frontcaliperToFrontrotorX"
                    label="frontcaliperToFrontrotorX"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="frontcaliperToFrontrotorY"
                    label="frontcaliperToFrontrotorY"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="rearcaliperToRearrotorX"
                    label="rearcaliperToRearrotorX"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="rearcaliperToRearrotorY"
                    label="rearcaliperToRearrotorY"
                  >
                    <Input />
                  </Form.Item>
                </>
              ) : null}
              {partToPoint?.partType === "lever" ? (
                <>
                  <Form.Item name="leverTobarsX" label="leverTobarsX">
                    <Input />
                  </Form.Item>
                  <Form.Item name="leverTobarsY" label="leverTobarsY">
                    <Input />
                  </Form.Item>
                </>
              ) : null}
              {partToPoint?.partType === "saddles" ? (
                <>
                  <Form.Item
                    name="saddlesToFramesetsX"
                    label="saddlesToFramesetsX"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="saddlesToFramesetsY"
                    label="saddlesToFramesetsY"
                  >
                    <Input />
                  </Form.Item>
                </>
              ) : null}
              {partToPoint?.partType === "tyres" ? (
                <>
                  <Form.Item
                    name="fronttyresToFramesetsX"
                    label="fronttyresToFramesetsX"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="fronttyresToFramesetsY"
                    label="fronttyresToFramesetsY"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="reartyresToFramesetsX"
                    label="reartyresToFramesetsX"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="reartyresToFramesetsY"
                    label="reartyresToFramesetsY"
                  >
                    <Input />
                  </Form.Item>
                </>
              ) : null}
              {partToPoint?.partType === "wheel" ? (
                <>
                  <Form.Item
                    name="frontwheelToFramesetsX"
                    label="frontwheelToFramesetsX"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="frontwheelToFramesetsY"
                    label="frontwheelToFramesetsY"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="rearwheelToFramesetsX"
                    label="rearwheelToFramesetsX"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="rearwheelToFramesetsY"
                    label="rearwheelToFramesetsY"
                  >
                    <Input />
                  </Form.Item>
                </>
              ) : null}
              <Form.Item>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                    style={{ margin: "0 10px" }}
                  >
                    목록
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<CheckOutlined />}
                  >
                    저장
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </div>
  );
}
