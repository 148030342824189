import {
  Button,
  Divider,
  Form,
  Input,
  message,
  PageHeader,
  Upload,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  CheckOutlined,
  CloseOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import * as api from "../../apis/survey";
import { useEffect, useState } from "react";
import { errorHandler } from "src/utils/eventErrorHandler";

export default function Detail() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const params = useParams();

  const [pageTitle, setPageTitle] = useState("");

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [fileList, setFileList] = useState([]);

  const uploadProps = {
    name: "image",
    action: `https://info.birect.co.kr/api/v1/web_admin/result/${params.id}/image/`,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      setFileList(() => [info.file]);
    },
    fileList,
  };

  const onFinish = (values) => {
    editResult(values);
  };

  const editResult = (values) => {
    api
      .putResult({ ...result, ...values })
      .then((res) => {
        const { linusCode } = res.data;
        if (linusCode === 1) {
          message.success("설문 결과 내역이 수정되었습니다.");
          navigate(-1);
        }
      })
      .catch(errorHandler);
  };

  const fetchResult = () => {
    setLoading(true);

    api.getResult({ id: params.id }).then((res) => {
      const { linusCode, data } = res.data;

      if (linusCode === 1) {
        setResult(filterResult(data));
        setFileList((object) => [{ ...object, name: data.image }]);
        setLoading(false);
      }
    });
  };

  const filterResult = (data) => {
    if (!data) return;

    let result = {};

    for (const [key, value] of Object.entries(data)) {
      result[key] = value && value.id ? value.id : value;
    }
    return result;
  };

  useEffect(() => {
    setPageTitle("자전거 추천 결과 수정");
    fetchResult();
  }, []);

  return (
    <div>
      <PageHeader className="site-page-header" title={pageTitle} />
      <Divider />
      {!loading && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={result}
              scrollToFirstError
              style={{ width: "50%" }}
            >
              <Form.Item
                name="title"
                label="자전거 종류 명"
                rules={[
                  {
                    required: true,
                    message: "자전거 종류 명을 입력해주세요.",
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="desc" label="자전거 설명">
                <Input />
              </Form.Item>

              {/* <Form.Item name="frame" label="프레임">
                <Input />
              </Form.Item>

              <Form.Item name="bars" label="핸들바">
                <Input />
              </Form.Item>

              <Form.Item name="crankSet" label="구동계">
                <Input />
              </Form.Item>

              <Form.Item name="saddle" label="안장">
                <Input />
              </Form.Item>

              <Form.Item name="wheel" label="휠">
                <Input />
              </Form.Item>

              <Form.Item name="tire" label="타이어">
                <Input />
              </Form.Item> */}

              <Form.Item name="image" label="결과 이미지">
                <Upload {...uploadProps}>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>

              <Form.Item
                name="url"
                label="이동할 링크"
                rules={[
                  {
                    required: true,
                    message: "이동할 링크를 입력해주세요.",
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    htmlType="submit"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                    style={{ margin: "0 10px" }}
                  >
                    목록
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<CheckOutlined />}
                  >
                    저장
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </div>
  );
}
