import axios from "axios";

const client = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? "https://info.birect.co.kr/api/v1/web_admin/" : 'http://localhost:8000/api/v1/web_admin/',
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("accessToken") ?? null;
    if (accessToken) {
      config.headers.common["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// client.interceptors.response.use(
//     (response) => {
//         // 요청 성공 시 특정 작업 수행
//         return response
//     },
//     (error) => {
//         // 요청 실패 시 특정 작업 수행
//         if (error.response.status === 403) {
//             alert('토큰이 만료되었습니다. 로그아웃 후 재 로그인 해 주세요.')
//             return
//         }
//         return Promise.reject(error)
//     }
// )

/*
  글로벌 설정 예시:

  // API 주소를 다른 곳으로 사용함
  client.defaults.baseURL = 'https://external-api-server.com/'

  // 헤더 설정
  client.defaults.headers.common['Authorization'] = 'Bearer a1b2c3d4';

  // 인터셉터 설정
  axios.intercepter.response.use(\
    response => {
      // 요청 성공 시 특정 작업 수행
      return response;
    },
    error => {
      // 요청 실패 시 특정 작업 수행
      return Promise.reject(error);
    }
  })
*/

export default client;
