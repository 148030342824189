import { message } from "antd";

export const errorHandler = (error) => {
  if (error.response.data.detail) {
    message.error(error.response.data.detail);
  } else if (error.response.status < 500) {
    for (let key of Object.keys(error.response.data)) {
      message.error(`${key}: ${error.response.data[key]}`);
    }
  } else {
    message.error("오류가 발생했습니다.");
  }
};
