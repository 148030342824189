export const menus = [
  {
    mainMenu: { title: "대시보드", path: "" },
  },
  {
    mainMenu: { title: "회원 관리", path: "user" },
  },
  {
    mainMenu: { title: "메인배너 관리", path: "banner" },
  },
  {
    mainMenu: { title: "부품 좌표 관리", path: "point" },
  },
  {
    mainMenu: { title: "부품 그룹 관리", path: "partset" },
  },
  {
    mainMenu: { title: "부품 배경 관리", path: "bg" },
  },
  {
    mainMenu: { title: "자전거 추천 관리", path: "survey" },
  },
  {
    mainMenu: { title: "공지사항", path: "notice" },
  },
  {
    mainMenu: { title: "FAQ", path: "faq" },
  },
  {
    mainMenu: { title: "1:1문의", path: "qna" },
  },
  {
    mainMenu: { title: "자전거 커스텀 내역", path: "share-data" },
  },
  {
    mainMenu: { title: "자전거 추천 내역", path: "survey-data" },
  },
];
