import styled from 'styled-components';

export const FlexRowReverseContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`;

export const FlexRowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FlexRowEndContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const FlexRowQuarterContainer = styled.div`
  width: 23%;
  display: flex;
  justify-content: space-between;
`;
