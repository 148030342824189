import client from "./client";

export const authCheck = ({ accessToken }) =>
  client.post("/user/token/verify/", { accessToken });

export const login = ({ email, password }) => {
  return client.post("/user/email/login/", {
    email: email,
    password: password,
  });
};

export const getUser = ({ id }) => client.get(`/user/${id}/`);

export const getUsers = ({
  ordering,
  page,
  size,
  isOnlyCount,
  isDeleted = 3,
}) => {
  return client.get("/user/", {
    params: {
      ordering,
      page,
      size,
      isOnlyCount,
      isDeleted,
    },
  });
};

export const deleteUser = ({ id }) => client.delete(`/user/${id}/`);

export const putUser = ({
  id,
  is_superuser,
  is_staff,
  is_active,
  date_joined,
  phone,
  email,
  password,
  name,
  agreeUsage,
  agreePrivacy,
}) =>
  client.put(`/user/${id}/`, {
    is_superuser,
    is_staff,
    is_active,
    date_joined,
    phone,
    email,
    password,
    name,
    agreeUsage,
    agreePrivacy,
  });
