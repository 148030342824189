import { Button, Divider, Form, Input, message, PageHeader } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import * as api from "../../apis/notice";
import { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { errorHandler } from "src/utils/eventErrorHandler";

export default function Detail() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const params = useParams();

  const [isCreatePage, setIsCreatePage] = useState(true);
  const [pageTitle, setPageTitle] = useState("");

  const [loading, setLoading] = useState(false);
  const [notice, setNotice] = useState(null);

  const onFinish = (values) => {
    if (isCreatePage) {
      createNotice(values);
    } else {
      editNotice(values);
    }
  };

  const createNotice = (values) => {
    api
      .postNotice({ ...values })
      .then((res) => {
        const { linusCode } = res.data;
        if (linusCode === 1) {
          message.success("공지사항이 추가되었습니다.");
          navigate(-1);
        }
      })
      .catch(errorHandler);
  };

  const editNotice = (values) => {
    api
      .putNotice({ ...notice, ...values })
      .then((res) => {
        const { linusCode } = res.data;
        if (linusCode === 1) {
          message.success("공지사항이 수정되었습니다.");
          navigate(-1);
        }
      })
      .catch(errorHandler);
  };

  const fetchNotice = () => {
    setLoading(true);

    api.getNotice({ id: params.id }).then((res) => {
      const { linusCode, data } = res.data;

      if (linusCode === 1) {
        setNotice(data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setIsCreatePage(params.id === "create");
  }, []);

  useEffect(() => {
    if (isCreatePage) {
      setPageTitle("공지사항 생성");
    } else {
      setPageTitle("공지사항 수정");
      fetchNotice();
    }
  }, [isCreatePage]);

  return (
    <div>
      <PageHeader className="site-page-header" title={pageTitle} />
      <Divider />
      {!loading && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={notice}
              scrollToFirstError
              style={{ width: "50%" }}
            >
              <Form.Item
                name="title"
                label="제목"
                rules={[
                  {
                    required: true,
                    message: "제목을 입력해주세요.",
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="content"
                label="내용"
                rules={[
                  {
                    required: true,
                    message: "내용을 입력해주세요.",
                    whitespace: true,
                  },
                ]}
              >
                <TextArea rows={10} />
              </Form.Item>

              <Form.Item>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    htmlType="submit"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                    style={{ margin: "0 10px" }}
                  >
                    목록
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<CheckOutlined />}
                  >
                    저장
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </div>
  );
}
